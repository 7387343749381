<template>
  <el-dialog
    title="查看"
    :visible.sync="serveDetailVisible"
    width="30%"
    :before-close="handleClose">
    <div>
      <el-form :model="ruleForm" ref="ruleForm" label-width="150px" class="demo-ruleForm">
        <el-form-item label="类型名称:" prop="name">
          <span>{{ruleForm.name || '--'}}</span>
        </el-form-item>
        <el-form-item label="服务内容及补贴标准:" prop="mycontent">
          <el-form-item label="" prop="elderlySubsidyChecked">
           <div v-if="ruleForm.serviceContent">
             <div v-if="ruleForm.serviceContent.includes('1')">
               <span>助老服务</span>
               <span class="ml30px">补贴 <span>{{ruleForm.elderlySubsidy}}</span>元/月</span>
             </div>
           </div>
          </el-form-item>
          <el-form-item label="" prop="informationSubsidyChecked">
           <div v-if="ruleForm.serviceContent">
             <div v-if="ruleForm.serviceContent.includes('2')">
               <span>信息服务</span>
               <span class="ml30px">补贴 <span>{{ruleForm.informationSubsidy}}</span> 元/月</span>
             </div>
           </div>
          </el-form-item>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
<!--    <el-button @click="handleClose">取 消</el-button>
    <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>-->
  </span>
  </el-dialog>
</template>

<script>
  import { getDetail } from "@/api/homeCasedCareServices"
  export default {
    name: "addOrEditObjTypeDilog",
    props: {
      serveDetailVisible: {
        type: Boolean,
        default: false
      },
      editRow: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data() {
      return {
        ruleForm: {
          name: '',
          informationSubsidy: 0, //信息补贴
          elderlySubsidy: 0, //助老补贴
          elderlySubsidyChecked: false,
          informationSubsidyChecked: false
        },
        rules: {
        }
      }
    },
    mounted() {
      this.getDetailPost()
    },
    methods: {
      handleClose() {
        this.$emit('update:serveDetailVisible', false)
      },
      async getDetailPost() {
        const  res = await getDetail({id: this.editRow.id})
        this.ruleForm = res.data
        this.ruleForm.serviceContent = res.data.serviceContent.split(',')
      }
    }
  }
</script>

<style scoped>
  .ml30px {
    display: inline-block;
    margin-left: 30px;
  }
</style>
